import React, {useState} from 'react'
import { Link } from "gatsby"

import styles from './menu.module.css'
import ArrowUp from '../../images/icon/arrow-up.svg'
import ArrowDown from '../../images/icon/arrow-down.svg'
import LocationInNYC from './locations-in-nyc'
import ContactPopup from '../contact/contact-popup'

import MenuClose from '../../images/icon/menu-close.svg'
import MenuOpen from '../../images/icon/menu-open.svg'
import MenuPhone from '../../images/icon/menu-phone.svg'

const Menus = ({items}) => {

	const [state, setState] = useState({
        modal: false,
        menu: false
    });

 	function selectModal(){
 	  setState({modal: !state.modal})
    }

 	function toggleMenu(){
 	  setState({menu: !state.menu})
    }

	return (
		<>
			<div className={styles.container}>
				<div className={styles.wrapper}>
					<img className={styles.menuPhoneLogo} src={MenuPhone} alt={`sks - phone`}/>
					<div className={styles.logoWrapper}>
						<Link to="/">
							<img src={items.logo.logo.publicURL} alt={`sks - logo`}/>
						</Link>
					</div>

					  <section className={styles.topNav}>
					    <input id={styles.menuToggle} type="checkbox" />
					    <label className={styles.menuButtonContainer} htmlFor={styles.menuToggle}>
					    	<img src={ state.menu ? MenuOpen : MenuClose} alt={`sks - menu`} onClick={ toggleMenu } />
					  	</label>
					    <ul className={styles.menu}>
						{
							items.menus.map((item, index)=>{
								return (
									<li key={index} className={`${item.hasChildren ? styles.menuChildrens : ''}`}>
										<Link to={item.slug} activeClassName={styles.isActive}>
											{item.name}

											{
												item.hasChildren && 
													<>
														<img className={styles.arrowUp} src={ArrowUp} alt={`sks - arrow-up`}/>
														<img className={styles.arrowDown} src={ArrowDown} alt={`sks - arrow-down`}/>
													</>
											}
										</Link>
										{
											item.hasChildren && item.key === 'locations-in-nyc' && 
												<LocationInNYC styles={styles}/>
										}
									</li>
								)
							})
						}

						<li className={styles.headerContact}>
							<a href="#" onClick={ selectModal }>Contact us</a>
						</li>
					    </ul>
					  </section>

				</div>
			</div>
			<ContactPopup displayModal={state.modal} closeModal={selectModal} />
		</>
	)
}

export default Menus;