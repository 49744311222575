import React from 'react';
import { Link, graphql, useStaticQuery} from "gatsby"

import styles from './footer.module.css'
import LocationInNYC from '../header/locations-in-nyc'

import Logo from '../../images/footer-logo.svg';
import Cell from '../../images/cell.svg'
import Envelop from '../../images/envelop.svg'

const Footer = () => {
	const query = useStaticQuery(graphql`
	query {
		allStrapiFooterBasicContentManager {
		    edges {
		      node {
		        logo {
		          publicURL
		        }
		        address {
		          name
		          icon {
		            publicURL
		          }
		        }
		      }
		    }
		  }
	  }
	`)

	let p = [];
	query.allStrapiFooterBasicContentManager.edges.forEach(({node})=>{
		p.push(node)
	})
	p = p[0];
	return (
		<footer>
			<div className={styles.wrapper}>
				<div className={styles.footerTop}>
					<div className={styles.footerMenusWrapper}>
						<div className={styles.addressWrapper}>
							<div className={styles.logoWrapper}>
								<img src={p.logo.publicURL} alt={`Sks-logo`}/>
							</div>
							<ul>
								{
									p.address.map((item, index)=>{
										return(
											<li key={index}>
												<img src={item.icon.publicURL} alt={`sks-phone`}/>
												<p dangerouslySetInnerHTML={{__html:item.name}}/>
											</li>
										)
									})
								}
							</ul>
						</div>
						
							<div className={styles.footerMenus}>
								<h5>About</h5>
								<ul>
									<li>
										<Link to="/about-us">
											About us
										</Link>
									</li>
									<li>
										<Link to="/blog">
											Blog
										</Link>
									</li>									
									<li>
										<Link to="/sitemap">
											Sitemap
										</Link>
									</li>

								</ul>
							</div>
							<div className={styles.footerMenus}>
								<h5>Service Areas</h5>
								<LocationInNYC styles={styles}/>
							</div>
							<div className={styles.footerMenus}>
								<h5>Legal Policies</h5>
								<ul>
									<li>
										<Link to="/privacy-policy">
											Privacy Policies
										</Link>
									</li>
								</ul>
							</div>						
					</div>
				</div>
				<div className={styles.footerBottomLine}/>
				<div className={styles.footerBottom}>
					<p>
						© Smart key services LLC 2020
					</p>
				</div>
			</div>
		</footer>
	)
}

export default Footer