import React from 'react'
import { graphql, useStaticQuery} from "gatsby"

import styles from './contact-popup.module.css'
import EmailForm from './email-form'

const ContactPopup = (props) => {

     const divStyle = { 
          display: props.displayModal ? 'block' : 'none'
     };

     function closeModal(e) {
        e.stopPropagation()
        props.closeModal()
     }

	const query = useStaticQuery(graphql`
	query {
		allStrapiHomeBannerBasicContentManager {
		    edges {
		      node {
		        emergancy_service_call_name
		        emergancy_service_call_icon {
		          publicURL
		        }
		        phone_no
		      }
		    }
		}
	  }
	`)

	let p = [];
	query.allStrapiHomeBannerBasicContentManager.edges.forEach(({node})=>{
		p.push(node)
	})
	p = p[0];

	return (
		<>
			<div 
	         className={styles.modal}
	         onClick={ closeModal }
	         style={divStyle} >
	            <div 
	             	className={styles.modalContent}
	             	onClick={ e => e.stopPropagation() } >
	             	<span 
	             	    className={styles.close}
	             	    onClick={ closeModal }>&times;
	             	</span>
	             	<h2 className={styles.contactH2}>Contact us</h2>
	             	<p className={styles.contactP}>Please feel free to contact us anytime</p>
					<div className={styles.contactWrapper, styles.contactWrapperC}>
						<div className={styles.contactInfo}>
							<div className={styles.contactWrapper}>
								<img className={styles.shake} src={p.emergancy_service_call_icon.publicURL} alt={`sks - phone`}/>
								<h3 dangerouslySetInnerHTML={{__html: p.emergancy_service_call_name}}/>
							</div>
							<h2>{p.phone_no}</h2>
						</div>
						<EmailForm styles={styles}/>
					</div>
	            </div>
	        </div>
		</>
	)
}

export default ContactPopup