import React from 'react'
import { graphql, useStaticQuery, Link} from "gatsby"

const L = ({styles}) => {
	const query = useStaticQuery(graphql`
	query {
      allStrapiLocations{
          edges{
            node{
              name,
              slug
            }
          }
        }
	  }
	`)

	let p = [];
	query.allStrapiLocations.edges.forEach(({node})=>{
		p.push(node)
	})

	return (
		<>
			<ul>
				{
					p.map((location, index)=>{
						return (
								<li key={index}>
									<Link to={`/location/${location.slug}`}>{location.name}</Link>
								</li>
							)
						})
					}
			</ul>
		</>
	)
}

export default L