import React,{useState, setState} from 'react'
import emailjs from 'emailjs-com';
import Toast from 'light-toast';

const EmailForm = ({styles, location}) => {
	const [state, setState] = useState({
        firstname: '',
        lastname: '',
        mobile: '',
        email: '',
        message: '',
        errors: {
			firstname: null,
			lastname: null,
			mobile: null,
			email: null,
			message: null
		},
    });

	const handeleSubmit = (e) => {
		e.preventDefault();

		for (let prop in state) {
			validation(prop, state[prop]);
		}
		for (let prop in state.errors) {
			if (state.errors[prop] !== null) {
				return 0;
			}
		}

		Toast.loading('Loading...');
		sendMail();
	}
	const handleChange = (e) =>{
		let name = e.target.name;
		let value = e.target.value
		setState({
			...state,
			[name]: value
		})
		// state[name] = value
	}

	function wordSepearate(word) {
		if(!word)
			return 0
		word = word.split('_');
		let d='';
		for(let prop in word){
			d+= `${word[prop]} `;
		}
		return d[0].toUpperCase() + d.slice(1)
	}

	function validation(name, value){
		let {errors} = state;
		if (Object.keys(state.errors).indexOf(name) > -1) {
			errors[name] = value.length < 1 ? `${wordSepearate(name)} field is required!` : null;
			if(name=== 'email') {
				errors['email'] = value.length < 1 ? 'Email field is required!': isEmail(value) === false ? `Please enter a valid email!` : null;
			}else if(name == 'mobile'){
				errors['mobile'] = value.length < 1 ? 'Mobile field is required!': isDigit(value) === false ? `Please enter a valid mobile!` : null;
			}
			setState({ errors, [name]: value })
		}
	}
	function isEmail(email){
	  const regex = /^((?!\.)[\w-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/;
	  return regex.test(email);
	}	
	function isDigit(mobile){
	  const regex = /^[0-9]*$/;
	  return regex.test(mobile);
	}	
	async function sendMail(){
		let templateID = process.env.EMAIL_TEMPLATE_ID;
		let serviceID = process.env.EMAIL_SERVICE_ID;
		let userID = process.env.EMAIL_USER_ID;
		const params = {
			firstname : `${state.firstname}`,
			lastname : `${state.lastname}`,
			email: state.email,
			mobile: state.mobile,
			message: state.message,
			location: location,
			recieved_mail: process.env.EMAIL_ID
		}
		try {
			await emailjs.send(serviceID, templateID, params, userID);
			Toast.hide();
			Toast.success('Email was sent successfully.', 5000);
			location = ''
			setState({
		        firstname: '',
		        lastname: '',
		        mobile: '',
		        email: '',
		        message: '',
		        errors: {
					firstname: null,
					lastname: null,
					mobile: null,
					email: null,
					message: null
				},
		    })
		}
		catch(error){
			console.log(error)
		}
	}
	return(
		<div className={styles.fromWrapper}>
			<span className={styles.orText}>Or</span>
			<form onSubmit={handeleSubmit}>
				<div className={`${styles.formGroup} ${styles.formGroupC}`}>
					<label></label>
					<input 
						type="text" 
						name="firstname" 
						required="required"
						value={state.firstname} 
						className={styles.formControl} 
						placeholder="First Name.."
						onChange={(e)=>handleChange(
						{
							target:{name: e.target.name,value:e.target.value}
						})}
					/>
					<span className={state.errors['firstname'] ? styles.vError: ''}>
						{state.errors['firstname']}
					</span>
				</div>
				<div className={`${styles.formGroup} ${styles.formGroupC}`}>
					<label></label>
					<input 
						type="text" 
						name="lastname" 
						required="required"
						value={state.lastname} 
						className={styles.formControl} 
						placeholder="Last Name.."
						onChange={(e)=>handleChange(
						{
							target:{name: e.target.name,value:e.target.value}
						})}
					/>
					<span className={state.errors['lastname'] ? styles.vError: ''}>
						{state.errors['lastname']}
					</span>
				</div>
				<div className={`${styles.formGroup} ${state.errors['mobile'] ? styles.inputError : ''}`}>
					<label></label>
					<input 
						type="text" 
						name="mobile" 
						required="required"
						value={state.mobile} 
						className={styles.formControl} 
						placeholder="Mobile.."
						onChange={(e)=>handleChange(
						{
							target:{name: e.target.name,value:e.target.value}
						})}
					/>
					<span className={state.errors['email'] ? styles.vError: ''}>
						{state.errors['mobile']}
					</span>
				</div>
				<div className={`${styles.formGroup} ${state.errors['email'] ? styles.inputError : ''}`}>
					<label></label>
					<input 
						type="text" 
						name="email" 
						required="required"
						value={state.email} 
						className={styles.formControl} 
						placeholder="Email.."
						onChange={(e)=>handleChange(
						{
							target:{name: e.target.name,value:e.target.value}
						})}
					/>
					<span className={state.errors['email'] ? styles.vError: ''}>
						{state.errors['email']}
					</span>
				</div>
				<div className={`${styles.formGroup} ${state.errors['message'] ? styles.inputError : ''}`}>
					<label></label>
					<textarea rows="5" 
						className={styles.formControl} 
						name="message" 
						required="required"
						value={state.message} 
						placeholder="Message.."
						onChange={(e)=>handleChange(
						{
							target:{name: e.target.name, value:e.target.value}
						})}
					/>
					<span className={state.errors['email'] ? styles.vError: ''}>
						{state.errors['message']}
					</span>
				</div>
				<div className={styles.submitButton}>
					<button>send</button>
				</div>
			</form>
		</div>
	)
}

export default EmailForm