import React from 'react'
import { graphql, useStaticQuery} from "gatsby"

import Menu from './menu'

const HI = () => {
	const query = useStaticQuery(graphql`
	query {
		allStrapiHeaderBasicContentManager {
		    edges {
		      	node {
		        	logo {
		          		publicURL
		        	}
		      	}
		    }
	  	}
	  }
	`)

	let p = [];
	query.allStrapiHeaderBasicContentManager.edges.forEach(({node})=>{
		p.push(node)
	})
	p = p[0];
	const menus = [{
		key: 'about-us',
		name: 'About us',
		slug: '/about-us'
	},
	{
		key: 'emergency-services',
		name: 'Emergency services',
		slug: '/emergency-services'
	},
	{
		key: 'automobile-lockout',
		name: 'Automobile lockout',
		slug: '/automobile-lockout'
	},
	{
		key: 'locations-in-nyc',
		name: 'Service Areas',
		slug: '/locations-in-nyc',
		hasChildren: true
	},
	{
		key: 'brands-we-help',
		name: 'Car Makes',
		slug: '/brands-we-help'
	}
	];
	const items = {
		logo : p,
		menus: menus
	}
	return (
		<>
			<Menu items={items}/>
		</>
	)
}

export default HI